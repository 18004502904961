import { IconSvg } from '@graphcommerce/next-ui'
import iconLocation from '@graphcommerce/next-ui/icons/location.svg'
import { Box, Grid, Typography } from '@mui/material'

const MailToLink = ({ email }) => (
  <a
    style={{
      textDecoration: 'underline',
      color: 'black',
    }}
    href={`mailto:${email}`}
  >
    {email}
  </a>
)

const PhoneLink = ({ number }) => (
  <a
    style={{
      textDecoration: 'underline',
      color: 'black',
    }}
    href={`tel:${number}`}
  >
    {number}
  </a>
)

export function Headquarter() {
  return (
    <>
      <Box
        sx={{
          paddingTop: 1,
          paddingBottom: 10,
        }}
      >
        <Grid
          container
          spacing={2}
          style={{
            flexGrow: 1,
          }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <IconSvg
              src={iconLocation}
              size='large'
              style={{
                fontSize: 48,
                marginBottom: 3,
              }}
            />
            <div>
              <Typography variant='h3' align='center'>
                Sede di Firenze
              </Typography>
              <Typography variant='body1' align='center'>
                <a
                  style={{
                    textDecoration: 'underline',
                    color: 'black',
                  }}
                  href='https://www.google.com/maps/search/?api=1&query=Jumboffice+Via+Vittorio+Emanuele+19%2C+50041+Calenzano+FI'
                  target='_blank'
                  rel='nofollow noopener noreferrer'
                  onClick={(e) => {
                    if (
                      navigator.platform.indexOf('iPhone') !== -1 ||
                      navigator.platform.indexOf('iPad') !== -1 ||
                      navigator.platform.indexOf('iPod') !== -1
                    ) {
                      e.preventDefault()
                      window.open(
                        'maps://maps.google.com/maps?daddr=Jumboffice+Via+Vittorio+Emanuele+19%2C+50041+Calenzano+FI&amp;ll=',
                      )
                    }
                  }}
                >
                  Vittorio Emanuele, 19 (uscita autostrada A1) Calenzano, Firenze
                </a>
              </Typography>
              <Typography variant='body1' align='center'>
                <MailToLink email='info@jode.it' />
              </Typography>
              <Typography variant='body1' align='center'>
                <PhoneLink number='05588324' />
              </Typography>
              <Typography variant='h4' align='center'>
                Orario di apertura showroom
              </Typography>
              <Typography variant='body1' align='center'>
                dal Lunedi al Venerdi 9-13 / 14:30-19
              </Typography>
              <Typography variant='body1' align='center'>
                Sabato 9.00-13.00 (solo Showroom)
              </Typography>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <IconSvg
              src={iconLocation}
              size='large'
              style={{
                fontSize: 48,
                marginBottom: 3,
              }}
            />
            <div>
              <Typography variant='h3' align='center'>
                Sede di Roma
              </Typography>
              <Typography variant='body1' align='center'>
                <a
                  style={{
                    textDecoration: 'underline',
                    color: 'black',
                  }}
                  href='https://www.google.com/maps/search/?api=1&query=Jumboffice+Via+Tiburtina%2C+1010%2C+00156+Roma+RM'
                  target='_blank'
                  rel='nofollow noopener noreferrer'
                  onClick={(e) => {
                    if (
                      navigator.platform.indexOf('iPhone') !== -1 ||
                      navigator.platform.indexOf('iPad') !== -1 ||
                      navigator.platform.indexOf('iPod') !== -1
                    ) {
                      e.preventDefault()
                      window.open(
                        'maps://maps.google.com/maps?daddr=Jumboffice+Via+Tiburtina%2C+1010%2C+00156+Roma+RM&amp;ll=',
                      )
                    }
                  }}
                >
                  Via Tiburtina, 1010 00156 ROMA
                </a>
              </Typography>
              <Typography variant='body1' align='center'>
                <MailToLink email='info@jode.it' />
              </Typography>
              <Typography variant='body1' align='center'>
                <PhoneLink number='0641228004' />
              </Typography>
              <Typography variant='h4' align='center'>
                Orario di apertura showroom
              </Typography>
              <Typography variant='body1' align='center'>
                dal Lunedi al Venerdi 9.00 - 18.00
              </Typography>
              <Typography variant='body1' align='center'>
                Sabato chiuso
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Box>
      {/* <Box bgcolor='black' padding='1rem 0'>
        <Typography variant='h2' align='center' color='white'>
          Informiamo che i negozi di Firenze e Roma rimarrano chiusi per ferie dal 8 al 25 agosto
        </Typography>
      </Box> */}
    </>
  )
}
